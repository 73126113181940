<template>
	<v-layout>
		<Dialog :dialog="personDialog" :dialogWidth="1000">
			<template v-slot:title> Update Contact Person </template>
			<template v-slot:body>
				<v-form
					ref="personForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="appendContactPerson"
				>
					<v-row class="mx-3">
						<v-col md="4" class="mb-3">
							<label class="font-size-14 font-weight-500 required" for="y-salutation">Salutation</label>
							<SelectInput
								hide-top-margin
								class="mt-1"
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								:items.sync="listTitle"
								id="salutation"
								placeholder="Title"
								v-model.trim="contactPerson.salutation"
							></SelectInput>
						</v-col>
						<v-col md="4" class="mb-3">
							<label class="font-size-14 font-weight-500 required" for="y-first_name">First Name</label>
							<TextInput
								hide-top-margin
								class="mt-1"
								hide-details
								placeholder="First Name"
								v-model.trim="contactPerson.first_name"
								:rules="[
									vrules.required(contactPerson.first_name, 'first name'),
									vrules.minLength(contactPerson.first_name, 'first name', 2),
									vrules.maxLength(contactPerson.first_name, 'first name', 100),
								]"
								:class="{
									required: !contactPerson.first_name,
								}"
								v-on:keyup="appendDisplayName()"
								:disabled="pageLoading"
								:loading="pageLoading"
							></TextInput>
						</v-col>
						<v-col md="4" class="mb-3">
							<label
								class="font-size-14 font-weight-500"
								:class="{ required: value && value.length < 1 }"
								for="y-last_name"
								>Last Name</label
							>
							<TextInput
								hide-top-margin
								class="mt-1"
								hide-details
								placeholder="Last Name"
								v-model.trim="contactPerson.last_name"
								:rules="[
									value && value.length < 1 ? vrules.required(contactPerson.last_name, 'Last name') : true,
									vrules.minLength(contactPerson.last_name, 'Last name', 2),
									vrules.maxLength(contactPerson.last_name, 'Last name', 100),
								]"
								:class="{
									required: value && value.length < 1 ? !contactPerson.last_name : false,
								}"
								v-on:keyup="appendDisplayName()"
								:disabled="pageLoading"
								:loading="pageLoading"
							></TextInput>
						</v-col>
						<v-col md="4" class="mb-3">
							<label class="font-size-14 font-weight-500 required" for="y-display_name"
								>Display Name</label
							>
							<TextValidateInput
								hide-details
								hide-top-margin
								:disabled="pageLoading"
								:loading="pageLoading"
								placeholder="Display Name"
								id="display-name"
								:rules="[
									vrules.required(contactPerson.display_name, 'Display Name'),
									vrules.minLength(contactPerson.display_name, 'display name', 2),
									vrules.maxLength(contactPerson.display_name, 'display name', 100),
								]"
								:class="{
									required: !contactPerson.display_name,
								}"
								:validationField="{
									url_type: type,
									filter_type: 'contact_person',
									field: 'display_name',
								}"
								:parent-id="parentId"
								:current-id="contactPerson.id"
								show-dropdown
								v-model="contactPerson.display_name"
							></TextValidateInput>
						</v-col>
						<v-col md="4" class="mb-3">
							<label class="font-size-14 font-weight-500 required" for="y-mobile">Mobile</label>
							<PhoneInput
								required
								hide-details
								hide-top-margin
								class-name="mt-1"
								:disabled="pageLoading"
								:loading="pageLoading"
								id="contactperson-fax"
								placeholder="Mobile"
								v-model="contactPerson.mobile"
								:validation-field="{
									url_type: type,
									filter_type: 'contact_person',
									field: 'phone_number',
								}"
								:parent-id="parentId"
								:current-id="contactPerson.id"
							>
							</PhoneInput>
							<PhoneTemplate
								v-if="false"
								hide-top-margin
								class="mt-1"
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								:rules="[
									vrules.required(contactPerson.mobile, 'Mobile'),
									vrules.phoneNumber(contactPerson.mobile, 'Mobile', 12),
								]"
								:class="{
									required: !contactPerson.mobile,
								}"
								id="y-mobile"
								placeholder="Phone Number"
								v-model="contactPerson.mobile"
							></PhoneTemplate>
						</v-col>
						<v-col md="4" class="mb-3">
							<label class="font-size-14 font-weight-500" for="did-mobile">DID</label>
							<PhoneInput
								hide-top-margin
								class-name="mt-1"
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								placeholder="DID"
								v-model="contactPerson.did"
								:validation-field="{
									url_type: type,
									filter_type: 'contact_person',
									field: 'did',
								}"
								:parent-id="parentId"
								:current-id="contactPerson.id"
							>
							</PhoneInput>
						</v-col>
						<v-col md="4" class="mb-3">
							<label class="font-size-14 font-weight-500 required" for="y-email">Email</label>
							<EmailInput
								hide-top-margin
								class="mt-1"
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								:id="`person-email`"
								placeholder="Email Id"
								v-model.trim="contactPerson.email"
								:class="{
									required: !contactPerson.email,
								}"
								:rules="[vrules.required(contactPerson.email, 'Email')]"
								:validation-field="{
									url_type: type,
									filter_type: 'contact_person',
									field: 'email',
								}"
								:parent-id="parentId"
								:current-id="contactPerson.id"
							></EmailInput>
						</v-col>
						<v-col md="4" class="mb-3">
							<label class="font-size-14 font-weight-500" for="designation">Designation</label>
							<AutoCompleteInput
								hide-top-margin
								class="mt-1"
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="designation"
								placeholder="Designation"
								v-model="contactPerson.designation"
								:items="designationList"
								append-outer-icon="mdi-cog"
								v-on:click:append-outer="manageDesignationDialog = true"
							></AutoCompleteInput>
						</v-col>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn v-on:click="$emit('close', true)" depressed tile> Cancel </v-btn>
				<v-btn
					v-on:click="appendContactPerson"
					:disabled="!formValid"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
				>
					Update
				</v-btn>
			</template>
		</Dialog>
		<template v-if="manageDesignationDialog">
			<ManageDesignation
				:dialog="manageDesignationDialog"
				:list="designationList"
				v-on:close="manageDesignationDialog = false"
				v-on:success="getOption('designation')"
			></ManageDesignation>
		</template>
	</v-layout>
</template>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import EmailInput from "@/view/components/EmailInput";
import PhoneTemplate from "@/view/components/Phone";
import SelectInput from "@/view/components/SelectInput";
import ManageDesignation from "@/view/components/Manage-Designation.vue";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import { SET_ERROR } from "@/core/services/store/common.module";
import { cloneDeep, filter /* lowerCase */ } from "lodash";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import PhoneInput from "@/view/components/PhoneInput";
import TextValidateInput from "@/view/components/TextValidateInput";

export default {
	model: {
		prop: "value",
		event: "input",
	},
	data() {
		return {
			formValid: true,
			contactPersons: [],
			designationList: [],
			manageDesignationDialog: false,
			pageLoading: false,
			selectedPerson: {},
			contactPerson: {
				id: null,
				salutation: "mr",
				first_name: null,
				last_name: null,
				display_name: null,
				email: null,
				mobile: null,
				did: null,
				designation: null,
			},
		};
	},
	components: {
		Dialog,
		TextInput,
		EmailInput,
		SelectInput,
		ManageDesignation,
		AutoCompleteInput,
		PhoneTemplate,
		PhoneInput,
		TextValidateInput,
	},
	props: {
		value: {
			type: Object,
			default() {
				return {};
			},
		},
		parentId: {
			type: [String, Number],
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
		persons: {
			type: Array,
			default() {
				return [];
			},
		},
		personDialog: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		personDialog() {
			this.initComponent();
			this.initialize();
		},
	},
	methods: {
		getOption() {
			this.$store.dispatch(SET_LOCAL_DB).then(() => {
				this.initialize();
			});
		},
		/* initialize() {
			this.designationList = this.localDB("designation", []);
		}, */
		initialize() {
			this.designationList = this.localDB("designation", []);
			const isCategoryExist = this.designationList.some(
				(ele) => ele.value === this.contactPerson.designation
			);
			if (!isCategoryExist) {
				this.contactPerson.designation = null;
			}
		},

		initComponent() {
			this.contactPerson = cloneDeep(this.value);
			this.selectedPerson = cloneDeep(this.value);
			this.contactPersons = this.persons;
		},
		appendDisplayName() {
			const result = [];
			const { first_name, last_name } = this.contactPerson;
			if (first_name) {
				result.push(first_name);
			}
			if (last_name) {
				result.push(last_name);
			}
			this.contactPerson.display_name = result.join(" ");
		},
		async appendContactPerson() {
			if (!this.$refs.personForm.validate()) {
				return false;
			}

			/* let email = this.contactPerson.email;
			let mobile = this.contactPerson.mobile;
			let did = this.contactPerson.did; */
			let display_name = this.contactPerson.display_name;

			const _persons = filter(this.contactPersons, (row) => {
				return row.email != this.selectedPerson.email;
			});

			/* var emailsObj = filter(_persons, function (contactpersn) {
				if (lowerCase(contactpersn.email) == lowerCase(email)) return contactpersn.email;
			});
			if (emailsObj.length > 0) {
				this.$store.commit(SET_ERROR, [{ model: true, message: `Email ${email} already is used` }]);
				return false;
			}
			var mobilesObj = filter(_persons, function (contactpersn) {
				if (contactpersn.mobile == mobile) return contactpersn.mobile;
			});
			if (mobilesObj.length > 0) {
				this.$store.commit(SET_ERROR, [{ model: true, message: `Phone ${mobile} already is used` }]);
				return false;
			}
			var didObj = filter(_persons, function (contactpersn) {
				if (contactpersn.did == did) return contactpersn.did;
			});
			if (didObj.length > 0) {
				this.$store.commit(SET_ERROR, [{ model: true, message: `DID ${did} already is used` }]);
				return false;
			} */
			var displayNamesObj = filter(_persons, function (contactpersn) {
				if (contactpersn.display_name == display_name) return contactpersn.display_name;
			});
			if (displayNamesObj.length > 0) {
				this.$store.commit(SET_ERROR, [
					{ model: true, message: `Display name ( ${display_name} ) already is used` },
				]);
				return false;
			}

			//console.log({ contactPerson: this.contactPerson });

			this.$emit("input", this.contactPerson);
			this.$emit("change", this.contactPerson);
			this.$emit("update:person", this.contactPerson);
			this.$emit("close", true);
		},
	},
	mounted() {
		this.initComponent();
		this.initialize();
	},
	computed: {
		...mapGetters(["localDB"]),
	},
};
</script>
